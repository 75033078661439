import Image from "next/image"
import React from "react"
import { SimplifiedNewsBookmakerQuote } from "types/newsBookmakerQuote"

type Props = {
  key: number
  quote: SimplifiedNewsBookmakerQuote
}

export default function PredictionImageBookmaker(props: Props) {
  const { key, quote } = props

  return (
    <>
      <a key={key} className={"bookmaker"} href={quote.bookmakerBonus?.url || undefined}>
        <div className={"bookmaker-image-div"}>
          {quote.bookmakerBonus?.image?.path && (
            <Image src={quote.bookmakerBonus?.image?.path} alt={quote.bookmakerBonus?.name} width={170} height={70} />
          )}
        </div>
        <div className={"bookmaker-value"}>
          <span>{quote.quote}</span>
        </div>
      </a>
    </>
  )
}
